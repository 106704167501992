import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate } from "gatsby"
import Parse from '../vendor/Parse'
const { useEffect, } = React;
const Profile1 = "images/2021-01-30.jpg";

const iframeStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%'
}

 const iframeContainerStyle = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  paddingTop: '56.25%', /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

const IndexPage = () => {


  const [items, setItems] = React.useState([])
  const getAllEvents = () => {
    return Parse.Cloud.run('eventList')
  }

  useEffect(() => {
    getEvent()
  }, [])

  const getEvent = async () => {
    const items = await getAllEvents();
    setItems(items)
  }



  return <Layout>
    <Grid classes="col-12 col-xl-4">
      {
        items?.map(item => <Card
          cover={item.photo}
          title={"Neil Sims"}
          subtitle={"sub"}
          text="sdfds"
          topContent={<div style={iframeContainerStyle}>
            <iframe style={iframeStyle} src="https://www.youtube.com/embed/GA2lcBT_Bs8?si=5G1uO5rjrFQKhMK8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>}
        />)
      }

    </Grid>
  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
